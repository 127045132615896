export const global_data =
{
  "lab": {

      "title": "LAB",
      "content": [
         {
              "year": "2018",
              "image": "img/lab/immer3.png",
              "title": "AFRAME IMMERSIVE VIDEO",
              "explain": "A component to display 360/180 stereo/mono videos in WebVR (AFrame) with just one line of HTML, including video control bar.",
              "client": "",
              "credits": "",
              "link": "https://github.com/oscarmarinmiro/aframe-immersive-video"
          },
          {
              "year": "2018",
              "image": "img/lab/CCCBLab2.png",
              "title": "TECH VS MAGIC",
              "explain": "An interactive piece exploring the magical nature of technology.",
              "client": "CCCBLAB",
              "link": "http://lab.cccb.org/en/arthur-c-clarke-any-sufficiently-advanced-technology-is-indistinguishable-from-magic/#wasclarkeright",
              "credits": "Text, concept and interactive piece: Óscar Marín"
          },
          {
              "year": "2015",
              "image": "img/lab/AS_Lab.png",
              "title": "AFRAME STEREO COMPONENT",
              "explain": "Aframe VR component to enable separate eye rendering of objects in WebVR (Stereo Videos or Images)",
              "client": "",
              "credits": "",
              "link": "https://github.com/oscarmarinmiro/aframe-stereo-component"

          },
          {
              "year": "2014",
              "image": "img/lab/3D_Network.png",
              "title": "IMMERSIVE NETWORK VIZ",
              "explain": "An early experiment to display 3D networks in real time (from Twitter retweet network) in the Oculus Rift",
              "client": "",
              "credits": "",
              "link": "https://medium.com/@outliers/inmersive-real-time-network-visualization-with-oculus-rift-bb9e6fb3ac2"
          },
          {
              "year": "2013",
              "image": "img/lab/OG_Project.png",
              "title": "#OCCUPYGEZI",
              "explain": "A data experiment on the viral power of images, measuring virality metrics and visualizing viral geometries of image propagation on Twitter about turkish revolts in Gezi Park.",
              "client": "",
              "link": "http://viralgezi.outliers.es/",
              "credits": "Concept, Programming and DataViz: Óscar Marín. Narrative and text: Yolanda Quintana"
          },

      ]


  },

  "work": {
      "title": "FEATURED WORK",
      "content": [
          {
              "year": "2018",
              "image": "img/projects/dataverse.jpg",
              "title": "DATAVERSE",
              "explain": "A WebVR platform/library to integrate data visualizations and immersive media (360 videos and photos) through a modular approach.",
              "client": "Google News Lab, Online News Association, Knight Foundation",
              "credits": "Concept, Development and UX: Óscar Marín. Design: Hermes Carretero",
              "link": "https://dataverse.xyz/"
          },
          {
              "year": "2018",
              "image": "img/projects/1O.jpg",
              "title": "\"1 D'OCTUBRE\" EXHIBITION (VR)",
              "explain": "A VR app running on Oculus GO, recreating the events that took place on the <a href='https://en.wikipedia.org/wiki/2017_Catalan_independence_referendum' target='_blank'>2017 Catalan Independence Referendum</a>." +
              " The app combines Data Visualizations of that day events (maps and timelines) and 360+stereo video footage of people close-up testimonials",
              "client": "BARCELONA CITY COUNCIL",
              "credits": "Concept, Programming, Data, 360 Video: Oscar Marín",
          },
          {
              "year": "2017",
              "link": "https://www.adceurope.org/annual2016/",
              "image": "img/projects/ADCE_Project.png",
              "title": "ADCE ANNUAL OF AWARDS",
              "explain": "First interactive implementation of the Annual of European Awards, including some novel visualisations on the landing page. The new implementation includes a 'data-based' browsing of all the awards plus new interactivity on the detail view of works.",
              "client": "Art Directors Club of Europe / Design Hub",
              "credits": "Art Direction: Eva Álvarez, ADCE Team. Design: Eva Álvarez. Project Management: Maytee Rodríguez (ADCE). Front-end programming and visualisations: Hermes Carretero, Óscar Marín. Data plumbing: Óscar Marín."
          },
          {
              "year": "2017",
              "image": "img/projects/LUCA_Project_2.png",
              "title": "LUCA FLEET",
              "explain": "Rental car route data visualisations for office and rental optimisation. I implemented an algorithm to consolidate GPS car points into roads and the visualization of the resulting car movement patterns across days, hours and months.",
              "client": "Telefónica",
              "credits": "Back-end programming: José Miguel Hernando and José Ángel Martín. Front-end programming, visualisations, data plumbing: Óscar Marín."
          },
          {
              "year": "2016",
              "link": "http://www.atlasoftheatlantic.com/go-dataset",
              "image": "img/projects/AF_Project.png",
              "title": "ATLAS OF THE ATLANTIC",
              "explain": "An interactive online tool linking 100 key indicators tracking political, security, economic, environmental and energy connections binding the peoples of the Atlantic",
              "client": "Johns Hopkins University",
              "credits": "Project Management and Design:One Big Robot. Implementation: Outliers Collective (Alejandro González, Rubén Abad, Oscar Marín)"
          },
          {
              "year": "2013",
              "link": "https://vimeo.com/71084828",
              "image": "img/projects/Beats.png",
              "title": "BARCELONA Beats",
              "explain": "A real-time visualization of Barcelona, including Foursquare check-ins, instagram photos, tweets, traffic density and 'Bicing' information.\n" +
                  "The map was projected on a 3D model of Barcelona, as seen <a href='https://vimeo.com/65518726' target='blank'>here</a> and animated through several days to see <a href='https://vimeo.com/71084828' target='_blank'>emerging city patterns</a>. The project has been exhibited in Barcelona, Madrid, Mexico and Chile.",
              "client": "CCCB",
              "credits": "Design: Rocío Márquez, Patricia Benítez. Front-end programming: Óscar Marín, Rubén Abad, Alejandro González. Data Science: Alberto González Paje"
          },
      ]

  },

  "about": {
    "title": "ABOUT",
    "content": [
        "I'm a Software Engineer based in Barcelona, focused on Data Visualization and VR development." +
        " I've been involved in 'Big Data' since 2001, developing large-scale Search Engines, Natural Language Processing applications and Network Analysis software.",
        "In 2012 I founded <a href='http://outliers.es' target='_blank'>Outliers Collective</a>, a small studio implementing Data Analysis software and interactive Data Visualizations.",
        "The VR bug bit me in 2014, and have been involved in experimental VR projects ever since. I'm also the host of the main <a href='https://www.meetup.com/1st-Barcelona-Virtual-Reality-meetup/'>VR meetup</a> in Barcelona.",
        "I work as a freelance DataViz and VR developer, you can contact me on the social links below."
    ]
  }
};

// export default global_data;
