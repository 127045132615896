import React, { Component } from 'react';
import './Header.css';
// import logo from '../img/logo.svg'

class Header extends Component {
    linkTo(link){
        // console.log("LINKING TO ", link);
        window.open(link, '_blank');
    }
    render() {
        return (
            <header className="header">
                {/*<img src={logo} className="header-logo" alt="logo"/>*/}
                <div className="headerTitle">ÓSCAR MARÍN MIRÓ</div>
                {/*<div className="headerSocial">*/}
                    {/*<div className='demopadding'>*/}
                        {/*<div className='icon social tw' onClick={() => this.linkTo("https://twitter.com/oscarmarinmiro")}><i className='fa fa-twitter'></i></div>*/}
                        {/*<div className='icon social gh' onClick={() => this.linkTo("https://github.com/oscarmarinmiro")}><i className='fa fa-github'></i></div>*/}
                        {/*<div className='icon social in' onClick={() => this.linkTo("https://es.linkedin.com/in/oscarmarinmiro")}><i className='fa fa-linkedin'></i></div>*/}
                        {/*<div className='icon social en' onClick={() => this.linkTo("mailto:oscar@outliers.es")}><i className='fa fa-envelope'></i></div>*/}
                    {/*</div>*/}
                {/*</div>*/}
            </header>
        );
    }
}

export default Header;
