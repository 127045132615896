import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {

    linkTo(link){
        // console.log("LINKING TO ", link);
        window.open(link, '_blank');
    }

    render() {
        return (
            <div className="footer">


                <div className="headerSocial">
                        <div className='iconfooter social tw' onClick={() => this.linkTo("https://twitter.com/oscarmarinmiro")}><i className='fa fa-twitter'></i></div>
                        <div className='iconfooter social gh' onClick={() => this.linkTo("https://github.com/oscarmarinmiro")}><i className='fa fa-github'></i></div>
                        <div className='iconfooter social in' onClick={() => this.linkTo("https://es.linkedin.com/in/oscarmarinmiro")}><i className='fa fa-linkedin'></i></div>
                        <div className='iconfooter social en' onClick={() => this.linkTo("mailto:oscar@outliers.es")}><i className='fa fa-envelope'></i></div>
                </div>

                <div className="footerText">(c) Óscar Marín Miró, 2019</div>


            </div>
        );
    }
}

export default Footer;
