import React, { Component } from 'react';
import './Content.css';
import LabUnit from "./LabUnit";

class Lab extends Component {
    render() {

        let content = this.props.data;


        return (
            <div id="lab" className="lab content_section">

                <div className="content_title">
                    {content.title}
                </div>


                {
                    content.content.map(function(content, key){
                        return <LabUnit content={content} key = {key}/>;
                    })
                }
            </div>
        );
    }
}

export default Lab;
