import React, { Component } from 'react';
import {global_data} from './data/data';
import Header from './components/Header';
import NavBar from './components/NavBar';
import Content from './components/Content';
import Footer from './components/Footer';
import './App.css';

class App extends Component {

   state = ({
      data: global_data,
      menu_selected: "about"
    });

   constructor(props){
       super(props);

       this.handleScroll = this.handleScroll.bind(this);
       this.handleNavClick = this.handleNavClick.bind(this);
   }

   handleNavClick(item){

       // console.log(this.state);

       this.setState({menu_selected: item});

       let about_scroll = document.getElementById("about").offsetTop - 140;
       let work_scroll = document.getElementById("work").offsetTop - 140;
       let lab_scroll = document.getElementById("lab").offsetTop - 140;

       let scroll;

       switch(item){
           case 'about':
               scroll = about_scroll;
               break;
           case 'work':
               scroll = work_scroll;
               break;
           case 'lab':
               scroll = lab_scroll;
               break;
           default:
               scroll = about_scroll;
               break;
       }

       document.documentElement.scrollTop = document.body.scrollTop = scroll;

       return item;
   }

   handleScroll() {

          let scroll = window.scrollY;

          if (scroll > 0) {

              // 140 is the vertical offset of header + navbar

              let about_scroll = document.getElementById("about").offsetTop - 140;
              let work_scroll = document.getElementById("work").offsetTop - 140;
              let lab_scroll = document.getElementById("lab").offsetTop - 140;

              // console.log(this.state, window.scrollY, about_scroll, work_scroll, lab_scroll);

              if((scroll < work_scroll)){
                  this.setState({menu_selected: "about"});
              }
              else {
                  if((scroll < lab_scroll)){
                      this.setState({menu_selected: "work"});
                  }
                  else {
                      // if(this.state.menu_selected !== "lab") {
                          this.setState({menu_selected: "lab"});
                      // }
                  }
              }
          }
    }


  componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);
  }


  render() {

    // console.log(this.state.data);

    return (
      <div className="App">
          <Header/>
          <NavBar data={this.state.menu_selected} onClick={this.handleNavClick}/>
          <Content data={this.state.data}/>
          <Footer/>
      </div>
    );
  }
}

export default App;
