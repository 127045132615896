import React, { Component } from 'react';
import './Content.css';
import WorkUnit from './WorkUnit'

class Work extends Component {

    render() {

        let content = this.props.data;

        // console.log("WORK DATA", content);

        return (
            <div id="work" className="work content_section">

                <div className="content_title">
                    {content.title}
                </div>

                {
                    content.content.map(function(content, key){
                        // console.log("MAP KEY", key);
                        return <WorkUnit content={content} key={key}/>;
                    })
                }

                {/*<div className="work content_section">*/}
                {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default Work;
