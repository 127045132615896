import React, { Component } from 'react';
import './WorkUnit.css';

class WorkUnit extends Component {

    render() {

        const background = {backgroundImage: "url(\"" + this.props.content.image + "\")"};

        // console.log("PROPS PROPS PROPS", this.props);

        if(this.props.content.link) {
            return (
                <div className="content_unit">
                    <a href={this.props.content.link} target="_blank"><div className="content_thumbnail" style={background}/></a>
                    <div className="content_explain">
                        <div className="content_explain_title"><a href={this.props.content.link} target="_blank">{this.props.content.title}</a></div>
                        <div
                            className="content_explain_client_year">{this.props.content.client} ({this.props.content.year})
                        </div>
                        <div className="content_explain_text"
                             dangerouslySetInnerHTML={{__html: this.props.content.explain}}></div>
                        <div className="content_explain_credits">{this.props.content.credits}</div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="content_unit">
                    <div className="content_thumbnail" style={background}/>
                    <div className="content_explain">
                        <div className="content_explain_title">{this.props.content.title}</div>
                        <div
                            className="content_explain_client_year">{this.props.content.client} ({this.props.content.year})
                        </div>
                        <div className="content_explain_text"
                             dangerouslySetInnerHTML={{__html: this.props.content.explain}}></div>
                        <div className="content_explain_credits">{this.props.content.credits}</div>
                    </div>
                </div>
            );
        }
    }
}

export default WorkUnit;