import React, { Component } from 'react';
import './LabUnit.css';

class LabUnit extends Component {

    render() {

        const background = {backgroundImage: "url(\"" + this.props.content.image + "\")"};


        if(this.props.content.link) {
            return (
                <div className="lab_unit">
                    <a href={this.props.content.link} target="_blank"><div className="lab_thumbnail" style={background}/></a>
                    <div className="lab_explain">
                        <div className="lab_explain_title">
                            <a href={this.props.content.link} target="_blank">
                                {this.props.content.title}
                            </a>
                        </div>
                        <div
                            className="lab_explain_client_year">{this.props.content.client} ({this.props.content.year})
                        </div>
                        <div className="lab_explain_text"
                             dangerouslySetInnerHTML={{__html: this.props.content.explain}}></div>
                        <div className="lab_explain_credits">{this.props.content.credits}</div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="lab_unit">
                    <div className="lab_thumbnail" style={background}/>
                    <div className="lab_explain">
                        <div className="lab_explain_title">{this.props.content.title}</div>
                        <div
                            className="lab_explain_client_year">{this.props.content.client} ({this.props.content.year})
                        </div>
                        <div className="lab_explain_text"
                             dangerouslySetInnerHTML={{__html: this.props.content.explain}}></div>
                        <div className="lab_explain_credits">{this.props.content.credits}</div>
                    </div>
                </div>
            );
        }

    }
}

export default LabUnit;