import React, { Component } from 'react';
import './Content.css';

class About extends Component {

    linkTo(link){
        // console.log("LINKING TO ", link);
        window.open(link, '_blank');
    }

    render() {

        let content = this.props.data;

        // console.log("ABOUT DATA", content);

        return (
            <div id="about" className="about content_section">
                <div className="content_title">
                    {content.title}
                </div>


                {
                    content.content.map(function(text, key){
                        return <div className="content_paragraph" key={key} dangerouslySetInnerHTML = {{__html: text}}></div>
                    })
                }

                <div className="headerSocial">
                    <div className='demopadding'>
                        <div className='icon social tw' onClick={() => this.linkTo("https://twitter.com/oscarmarinmiro")}><i className='fa fa-twitter'></i></div>
                        <div className='icon social gh' onClick={() => this.linkTo("https://github.com/oscarmarinmiro")}><i className='fa fa-github'></i></div>
                        <div className='icon social in' onClick={() => this.linkTo("https://es.linkedin.com/in/oscarmarinmiro")}><i className='fa fa-linkedin'></i></div>
                        <div className='icon social en' onClick={() => this.linkTo("mailto:oscar@outliers.es")}><i className='fa fa-envelope'></i></div>
                    </div>
                </div>


            </div>
        );
    }
}

export default About;
