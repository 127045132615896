import React, { Component } from 'react';
import './NavBar.css';

class NavBar extends Component {


    constructor(props){

        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(value){
        // console.log("CLICK VALUE", value, this.props.onClick(value));

        this.props.onClick(value);
    }


    render() {

        // console.log("NAVBAR PROPS", this.props);

        return (
            <div className="nav_bar">
                <div className={this.props.data === "about" ? "nav_item selected" : "nav_item"} onClick={() => this.onClick("about")}>ABOUT</div>
                <div className={this.props.data === "work" ? "nav_item selected" : "nav_item"} onClick={() => this.onClick("work")}>WORK</div>
                <div className={this.props.data === "lab" ? "nav_item selected" : "nav_item"} onClick={() => this.onClick("lab")}>LAB</div>
            </div>
        );
    }
}

export default NavBar;
