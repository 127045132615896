import React, { Component } from 'react';
import './Content.css';
import About from './About'
import Work from './Work'
import Lab from './Lab'

class Content extends Component {

    shouldComponentUpdate(nextProps, nextState){
        return nextProps.data !== this.props.data;
    }

    render() {

        let render_data = this.props.data;

        return (
            <div className="content">
                <About data={render_data.about}/>
                <Work data={render_data.work}/>
                <Lab data={render_data.lab}/>
            </div>
        );
    }
}

export default Content;
